<template>
  <v-container>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Academic Program/Advisor Change Details: {{ stuName }} - {{ stuBannerId }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn to="/student/program-change/admin" text>Back to List</v-btn>
      </v-toolbar>
      <v-card-title>Status: {{ status }}</v-card-title>
      <v-card-text>
        <template v-if="status === 'Submitted'">
          <v-btn v-if="isRecords" color="success" class="mr-4" @click="sendToAdvisors()">Send to Advisors</v-btn>
          <v-btn v-if="isRecords" color="error" @click="returnToStudent()">Return to Student</v-btn>
        </template>
        <template v-else-if="status === 'Pending'">
          <v-btn v-if="isAdvisor" color="success" class="mr-4" @click="approve()">Approve</v-btn>
          <v-btn v-if="isRecords || isAdvisor" color="error" @click="returnToStudent()">Return to Student</v-btn>
        </template>
        <v-btn v-if="isRecords && status !== 'Processed'" color="success" class="ml-4" @click="markProcessed()">Mark as Processed</v-btn>
      </v-card-text>
      <v-row>
        <v-col>
          <submission-card :data="submission" :advisor-editable="true" class="mb-2" @update="updateAdvisor"></submission-card>
          <v-alert v-for="({ type, text }, index) in changes" :key="'changes-' + index" :type="type" dense class="ml-4 mr-4 mb-2">{{ text }}</v-alert>
        </v-col>
        <v-col>
          <v-card v-if="(status === 'Pending' || status === 'Approved' || status === 'Completed') && advisorApprovals.length > 0">
            <v-card-title>Advisor Approvals</v-card-title>
            <v-list>
              <v-list-item v-for="{ pidm, name, approved, removed, date } of advisorApprovals" :key="pidm">
                <v-list-item-content>
                  <v-list-item-title>{{ name }}</v-list-item-title>
                  <v-list-item-subtitle v-if="removed">**Removed from student's record**</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="date">Approved on: {{  stringFormatDate(date) }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-else>Not Approved</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon v-if="approved" color="success">fas fa-check-circle</v-icon>
                  <v-icon v-else color="info">fas fa-question-circle</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
          <v-card-title>Activity</v-card-title>
          <v-card v-if="showAddMessage">
            <v-card-text>
              <h3>Add a Message</h3>
              <v-textarea v-model="comment" label="Message" style="margin-top:1em" rows="3" outlined hide-details></v-textarea>
              <v-checkbox v-model="hiddenFromStudent" :label="'Hide this message from ' + stuName" :disabled="emailStudent" hide-details @change="(val) => { if (val) emailStudent = false }"></v-checkbox>
              <v-checkbox v-model="emailStudent" :label="'Email this message to ' + stuName" :disabled="hiddenFromStudent" hide-details @change="(val) => { if (val) hiddenFromStudent = false }"></v-checkbox>
              <v-checkbox v-model="emailAdvisors" label="Email this message to all of the advisor(s)" hide-details></v-checkbox>
            </v-card-text>
            <v-card-actions>
              <v-btn :disabled="comment === ''" @click="addComment" color="info">Add Message</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="showAddMessage = false">Cancel</v-btn>
            </v-card-actions>
          </v-card>
          <v-btn v-else @click="showAddMessage = true">Add Message</v-btn>
          <v-timeline dense>
            <v-timeline-item v-for="({ name, date, text, icon, color, visibleToStudent }, index) in timeline" :key="'timeline-' + index" :color="color">
              <template v-slot:icon>
                <v-icon small color="white">{{ icon }}</v-icon>
              </template>
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col>{{ name }}</v-col>
                    <v-col class="text-right">{{ stringFormatDate(date) }}</v-col>
                  </v-row>
                  <v-divider style="margin: .2em 0"></v-divider>
                  <p :style="visibleToStudent ? '' : 'font-style:italic'" style="margin-bottom:0" v-html="text"></p>
                  <template v-if="!visibleToStudent">
                    <v-divider style="margin: .2em 0"></v-divider>
                    <p style="font-weight:bold;margin-bottom:0">Not visible to student</p>
                  </template>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { computed, onMounted, ref } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'
import { inArray, getInArray } from '@/helpers/functions'
import { concentrations, convertData } from '@/components/student/program-change/options'

export default {
  components: {
    SubmissionCard: () => import('@/components/student/program-change/submission')
  },
  setup (props, { root }) {
    const id = computed(() => root.$route.params.id)
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const roles = computed(() => root.$store.state.roles)
    const isRecords = computed(() => 'Records' in roles.value || 'Technology Services' in roles.value)
    const isAdvisor = computed(() => inArray(user.value.pidm, advisorApprovals.value.filter(({ approved }) => !approved).map(({ pidm }) => pidm)))

    const term = ref('')
    const pidm = ref('')
    const stuName = ref('')
    const stuBannerId = ref('')
    const status = ref('')

    const submission = ref({})
    const original = ref({})
    const changes = ref([])
    const advisorApprovals = ref([])

    const timeline = ref([])

    async function load (data) {
      pidm.value = data.pidm
      term.value = data.term
      status.value = data.status
      stuName.value = data.name
      stuBannerId.value = data.bannerId
      submission.value = data
      timeline.value = data.timeline.reverse()
      advisorApprovals.value = []
      if ('advisorApproval' in data && Array.isArray(data.advisorApproval) && data.advisorApproval.length > 0) {
        for (const { pidm, approved, removed, date } of data.advisorApproval) {
          let { data: dirData } = await root.$feathers.service('directory/people').find({ query: { pidm } })
          if (dirData.length > 0) {
            advisorApprovals.value.push({ pidm, approved, removed, date, name: dirData[0].name.last + ', ' + dirData[0].name.first })
          } else {
            advisorApprovals.value.push({ pidm, approved, removed, date, name: '**Not In Directory; Contact Tech Services**' })
          }
        }
      }
    }

    const majors = ref([])
    const minors = ref([])

    onMounted(async () => {
      // Load the major options
      const { data: majorData } = await root.$feathers.service('forms/select-options').find({ query: { name: 'Major Code' } })
      const { options: majorOptions } = await root.$feathers.service('forms/select-options').get(majorData[0].value)
      majors.value = majorOptions
      // Load the minor options
      const { data: minorData } = await root.$feathers.service('forms/select-options').find({ query: { name: 'Minor Code' } })
      const { options: minorOptions } = await root.$feathers.service('forms/select-options').get(minorData[0].value)
      minors.value = minorOptions
      const record = await root.$feathers.service('student/major-change').get(id.value)
      load(record)
      const { data } = await root.$feathers.service('student/term-detail').find({ query: { pidm: record.pidm, term: record.term } })
      if (data.length > 0) {
        original.value = convertData(data[0].academics)
      }
      // Find main changes and list them here; these can be adding/removing/changing major, advisor, concentrations, minor, or certificates
      const addMajor = submission.value.program.filter(({ major }) => !inArray(major, original.value.program, 'major'))
      const remMajor = original.value.program.filter(({ major }) => !inArray(major, submission.value.program, 'major'))
      if (addMajor.length > 0) changes.value.push({ type: 'success', text: 'Added ' + addMajor.map(({ major }) => majors.value.reduce((prev, { text, value }) => value === major ? text : prev, '')).join('; ') + ' major' + (addMajor.length > 1 ? 's' : '') })
      if (remMajor.length > 0) changes.value.push({ type: 'error', text: 'Removed ' + remMajor.map(({ major }) => majors.value.reduce((prev, { text, value }) => value === major ? text : prev, '')).join('; ') + ' major' + (remMajor.length > 1 ? 's' : '') })
      for (const { major, advisor, primary, conc } of original.value.program) {
        const majorText = majors.value.reduce((prev, { text, value }) => value === major ? text : prev, '')
        const block = getInArray(major, submission.value.program, 'major').value
        if (block === false) {
          changes.value.push({ type: 'error', text: 'Removed ' + majorText + ' major' })
        } else {
          // There was a match on the major; check to see if there is a change in advisor or concentrations
          if (block.advisor !== advisor) {
            const { data: dirData } = await root.$feathers.service('directory/people').find({ query: { pidm: advisor } })
            if (dirData.length > 0) {
              const { first, last } = dirData[0].name
              changes.value.push({ type: 'error', text: 'Changed advisor for ' + majorText + ' major from ' + first + ' ' + last })
            }
          }
          if (((primary != null && primary !== '') || (block.primary != null && block.primary !== '')) && primary !== block.primary) {
            if (block.primary == null) changes.value.push({ type: 'error', text: 'Removed primary concentration: ' + primary })
            else if (primary == null) changes.value.push({ type: 'success', text: 'Added primary concentration: ' + block.primary })
            else changes.value.push({ type: 'info', text: 'Changed primary concentration from ' + primary })
          }
          const add = block.conc.filter((val) => !inArray(val, conc))
          const rem = conc.filter((val) => !inArray(val, block.conc))
          if (add.length > 0 && rem.length > 0) changes.value.push({ type: 'info', text: 'Changed concentrations for ' + majorText + ' major from: ' + rem.join('; ') })
          else if (add.length > 0) {
            const text = add.map((val) => concentrations[major].reduce((prev, { value, text }) => value === val ? text : prev, ''))
            changes.value.push({ type: 'info', text: 'Added ' + text.join('; ') + ' concentration' + (add.length > 1 ? 's' : '') + ' for ' + majorText + ' major' })
          } else if (rem.length > 0) {
            const text = rem.map((val) => concentrations[major].reduce((prev, { value, text }) => value === val ? text : prev, ''))
            changes.value.push({ type: 'error', text: 'Removed ' + text.join('; ') + ' concentration' + (rem.length > 1 ? 's' : '') + ' for ' + majorText + ' major' })
          }
        }
      }
      const addMinor = submission.value.minor.filter((val) => !inArray(val, original.value.minor))
      if (addMinor.length > 0) {
        const addText = addMinor.map((minor) => {
          const block = getInArray(minor, minors.value, 'value')
          return block ? block.value.text : minor
        })
        changes.value.push({ type: 'success', text: 'Added ' + addText.join('; ') + ' minor' + (addMinor.length > 1 ? 's' : '') })
      }
      const remMinor = original.value.minor.filter((val) => !inArray(val, submission.value.minor)).map((val) => minors.value.reduce((prev, { text, value }) => value === val ? text : prev, ''))
      if (remMinor.length > 0) {
        const remText = remMinor.map((minor) => {
          const block = getInArray(minor, minors.value, 'value')
          return block ? block.value.text : minor
        })
        changes.value.push({ type: 'error', text: 'Removed ' + remText.join('; ') + ' minor' + (remMinor.length > 1 ? 's' : '') })
      }
    })

    async function sendToAdvisors () {
      // Make sure that all advisors are set; if not, don't let them submit
      const timelineItem = {
        pidm: user.value.pidm,
        name: user.value.name,
        date: new Date(),
        text: 'Initial review completed',
        icon: 'far fa-check',
        color: 'success',
        visibleToStudent: true
      }
      await root.$feathers.service('student/major-change').patch(id.value, { $push: { timeline: timelineItem } })
      let valid = true
      const advisorApproval = []
      for (const { advisor } of submission.value.program) {
        if (advisor == null || advisor === '') {
          valid = false
        } else if (!inArray(advisor, advisorApproval.map(({ pidm }) => pidm))) {
          // Make sure we don't add duplicate advisors
          advisorApproval.push({ pidm: advisor })
        }
      }
      for (const { advisor } of submission.value.certificate) {
        if (advisor == null || advisor === '') {
          valid = false
        } else if (!inArray(advisor, advisorApproval.map(({ pidm }) => pidm))) {
          // Make sure we don't add duplicate advisors
          advisorApproval.push({ pidm: advisor })
        }
      }
      if (!valid) {
        alert('One or more advisors are not yet set. Please verify all advisors are set before proceeding.')
        return
      }
      // Email the advisors to let them know they have a pending program change to review
      for (const { pidm } of advisorApproval) {
        let { data: dirData } = await root.$feathers.service('directory/people').find({ query: { pidm } })
        if (dirData.length > 0) {
          let to = dirData[0].email
          if (process.env.NODE_ENV !== 'production') {
            to = 'jon.moon@covenant.edu'
          }
          try {
            await root.$feathers.service('system/email').create({ to, from: 'report-email@covenant.edu', subject: 'Program/Advisor Change for ' + stuName.value, html: stuName.value + ' has submitted a program change that requires your approval. Please reach out to the student if needed to discuss with them prior to approving. Follow this link to access the program change system to see any requests that are pending your approval: <a href="https://portal.covenant.edu/student/program-change/admin">https://portal.covenant.edu/student/program-change/admin</a>.<br/><br/>Thank You,<br/>Office of Records' })
            await root.$feathers.service('student/major-change').patch(id.value, { $push: { timeline: { ...timelineItem, text: 'Email sent to ' + to, icon: 'fal fa-envelope' } } })
          } catch (e) {
            root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error sending email to advisor: ' + e })
          }
        }
      }
      // Look up any advisors that they used to have that are not in the advisorApproval array (this means that advisor has been removed)
      const { data } = await root.$feathers.service('student/term-detail').find({ query: { pidm: pidm.value, term: term.value } })
      if (data.length > 0) {
        const advisor = data[0].academics.advisor.filter(({ code }) => code !== 'REC')
        const advisorPidms = advisorApproval.filter(({ code }) => code !== 'REC').map(({ pidm }) => pidm)
        for (let { pidm, email } of advisor) {
          if (!inArray(pidm, advisorPidms)) {
            // Add these removed advisors so they can also approve the request, stating in their email that they were removed
            advisorApproval.push({ pidm, status: 'Pending', removed: true })
            if (process.env.NODE_ENV !== 'production') {
              email = 'jon.moon@covenant.edu'
            }
            try {
              await root.$feathers.service('system/email').create({
                to: email,
                from: 'report-email@covenant.edu',
                subject: 'Program/Advisor Change for ' + stuName.value,
                html: stuName.value + ' has submitted a program change that requires your approval. Note that the change they submitted will result in you no longer being an advisor for them, but we ask that you still approve the request. If you have questions, you should reach out to the student to have them answered before approving. Please follow this link to access the program change system to see any requests that are pending your approval: <a href="https://portal.covenant.edu/student/program-change/admin">https://portal.covenant.edu/student/program-change/admin</a>.<br/><br/>Thank You,<br/>Office of Records'
              })
              await root.$feathers.service('student/major-change').patch(id.value, { $push: { timeline: { ...timelineItem, text: 'Email sent to ' + email, icon: 'fal fa-envelope' } } })
            } catch (e) {
              root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error sending email to former advisor: ' + e })
            }
          }
        }
      }
      // Update the system setting the status and the full advisorApproval array, and adding to the timeline that it was reviewed by the Records Office
      try {
        const upd = await root.$feathers.service('student/major-change').patch(id.value, { status: 'Pending', advisorApproval })
        load(upd)
        root.$store.dispatch('main/snackbar', { color: 'success', text: 'Save successful.' })
      } catch (e) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error saving update: ' + e })
      }
    }
    async function returnToStudent () {
      // Remove all advisor approvals; this will restart once the student submits the change request again
      const obj = {
        pidm: user.value.pidm,
        name: user.value.name,
        date: new Date(),
        text: 'Returned to Student',
        icon: 'fal fa-undo',
        color: 'error',
        visibleToStudent: true
      }
      try {
        const data = await root.$feathers.service('student/major-change').patch(id.value, { status: 'Returned', advisorApprovals: [], $push: { timeline: obj } })
        load(data)
        root.$store.dispatch('main/snackbar', { color: 'success', text: 'The program change request has been returned to the student' })
      } catch (e) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error returning it to the student: ' + e })
      }
    }
    async function approve () {
      // Add the approval for the current advisor
      // Find the index of the current user in the submission.advisorApproval array
      let stillPending = false
      const patch = {}
      for (let i = 0; i < submission.value.advisorApproval.length; i++) {
        const { pidm, approved } = submission.value.advisorApproval[i]
        if (pidm === user.value.pidm) {
          if (!approved) {
            patch['advisorApproval.' + i + '.approved'] = true
            patch['advisorApproval.' + i + '.date'] = new Date()
          }
        } else if (!approved) {
          stillPending = true
        }
      }
      if (JSON.stringify(patch) !== '{}') {
        if (!stillPending) {
          patch.status = 'Approved'
          try {
            root.$feathers.service('system/email').create({
              to: 'recordsoffice@covenant.edu',
              from: 'report-email@covenant.edu',
              subject: 'Program/Advisor Change for ' + stuName.value,
              html: 'The program/advisor change for ' + stuName.value + ' has been approved by all advisors and is ready to be processed. Please click here to access the system to process the changes: <a href="https://portal.covenant.edu/student/program-change/admin">https://portal.covenant.edu/student/program-change/admin</a>'
            })
          } catch (e) {
            root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error emailing the Records office that all advisors have approved. Please email them to let them know that the final approval has been given.' })
          }
        }
        patch.$push = {
          timeline: {
            pidm: user.value.pidm,
            name: user.value.name,
            date: new Date(),
            text: 'Advisor Approved',
            icon: 'far fa-check',
            color: 'success',
            visibleToStudent: true
          }
        }
        try {
          const data = await root.$feathers.service('student/major-change').patch(id.value, patch)
          load(data)
          root.$store.dispatch('main/snackbar', { color: 'success', text: 'Your approval has been saved.' })
        } catch (e) {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error saving approval: ' + e })
        }
      } else {
        alert('Could not find you in the pending advisor approvals; Please make sure you have not already approved this request and that your name is in the advisor approvals list')
      }
    }
    async function markProcessed () {
      try {
        await root.$feathers.service('student/major-change').patch(id.value, { recordsApproval: { user: user.value, date: new Date() }, status: 'Processed' })
        root.$store.dispatch('main/snackbar', { color: 'success', text: 'Program change marked as processed' })
        root.$router.push('/student/program-change/admin')
      } catch (e) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error processing program change: ' + e })
      }
    }

    async function updateAdvisor ({ type, index, advisor }) {
      try {
        const data = await root.$feathers.service('student/major-change').patch(id.value, { [type + '.' + index + '.advisor']: advisor })
        load(data)
        root.$store.dispatch('main/snackbar', { color: 'success', text: 'Advisor updated successfully' })
      } catch (e) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error updating advisor: ' + e })
      }
    }

    const showAddMessage = ref(false)
    const comment = ref('')
    const hiddenFromStudent = ref(false)
    const emailAdvisors = ref(false)
    const emailStudent = ref(false)

    async function addComment () {
      const obj = {
        pidm: user.value.pidm,
        name: user.value.name,
        date: new Date(),
        text: comment.value,
        icon: 'fas fa-comment-' + (hiddenFromStudent.value ? 'times' : 'check'),
        color: hiddenFromStudent.value ? 'error' : 'info',
        visibleToStudent: !hiddenFromStudent.value
      }
      timeline.value.splice(0, 0, obj)
      showAddMessage.value = false
      comment.value = ''
      if (emailAdvisors.value) {
        const { data } = await root.$feathers.service('directory/people').find({ query: { pidm: { $in: advisorApprovals.filter(({ pidm }) => pidm !== user.pidm).map(({ pidm }) => pidm) } } })
        let to = data.map(({ email }) => email)
        if (process.env.NODE_ENV !== 'production') {
          to = 'jon.moon@covenant.edu'
        }
        try {
          await root.$feathers.service('system/email').create({ to, from: user.value.email, subject: 'Program/Advisor Change for ' + stuName.value, html: comment.value + '<br/><br/><a href="https://portal.covenant.edu/student/program-change/admin">Access the Program/Advisor Change System</a>.<br/><br/>Thank You,<br/>Office of Records' })
        } catch (e) {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error sending email to advisors: ' + e })
        }
      }
      if (emailStudent.value) {
        const { data } = await root.$feathers.service('directory/people').find({ query: { pidm: pidm.value } })
        let to = data.map(({ email }) => email)
        if (process.env.NODE_ENV !== 'production') {
          to = 'jon.moon@covenant.edu'
        }
        try {
          await root.$feathers.service('system/email').create({ to, from: user.value.email, subject: 'Program/Advisor Change', html: comment.value + '<br/><br/><a href="https://portal.covenant.edu/student/program-change">Access the Program/Advisor Change System</a>.<br/><br/>Thank You,<br/>Office of Records' })
        } catch (e) {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error sending email to student: ' + e })
        }
      }
      try {
        root.$feathers.service('student/major-change').patch(id.value, { $push: { timeline: obj } }).then((data) => {
          timeline.value = data.timeline
          comment.value = ''
          hiddenFromStudent.value = false
        })
        root.$store.dispatch('main/snackbar', { color: 'success', text: 'Message saved' })
      } catch (e) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error saving message: ' + e })
      }
    }

    return {
      id,
      user,
      roles,
      isRecords,
      isAdvisor,
      term,
      pidm,
      stuName,
      stuBannerId,
      status,
      submission,
      original,
      changes,
      advisorApprovals,
      timeline,
      sendToAdvisors,
      returnToStudent,
      approve,
      markProcessed,
      updateAdvisor,
      showAddMessage,
      comment,
      hiddenFromStudent,
      emailAdvisors,
      emailStudent,
      addComment,
      stringFormatDate,
      concentrations
    }
  }
}
</script>
